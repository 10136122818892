.success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    font-size: 28px;
    background: url(../../assets/imgs/art-1.png) no-repeat left 30% center;
    background-size: 100px 100px;
}