.cover {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  &__text {
    font-size: 260px;
    color: #fff;
    text-align: center;
    display: table;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: -50%;
      top: -10%;
      width: 100%;
      height: 100%;
      background: url(../../assets/imgs/cover-image.png) no-repeat right center;
    }
  }
}

@media (max-width: 640px) {
  .cover {
    width: 90%;
    margin-top: 60px;
    &__text {
      font-size: 19vw;
      &:after {
        width: 40vw;
        height: 40vw;
        content: '';
        background-size: cover;
      }
    }
  }
}
