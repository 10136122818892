.agendaItem {
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 23px;
  margin-top: 45px;
  &__art {
    position: relative;
    &:after {
      content: '';
      width: 108px;
      height: 115px;
      position: absolute;
      right: 20px;
      top: -20px;
      z-index: 1;
      background-size: cover !important;
    }
    &-1 {
      &:after {
        background: url(../../assets/imgs/art-1.png) no-repeat;
      }
    }
    &-2 {
      &:after {
        background: url(../../assets/imgs/art-2.png) no-repeat;
      }
    }
    &-3 {
      &:after {
        background: url(../../assets/imgs/art-3.png) no-repeat;
      }
    }
    &-4 {
      &:after {
        background: url(../../assets/imgs/art-4.png) no-repeat;
      }
    }
  }
  &__time {
    width: 215px;
    font-size: 24px;
    text-transform: lowercase;
    border-radius: 20px;
    border: 0.5px solid #6a6a6a;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__container {
    width: calc(100% - 215px);
    border: 0.5px solid #6a6a6a;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    position: relative;
    z-index: 2;
  }
  &__title {
    font-size: 24px;
    max-width: 60%;
    text-transform: lowercase;
  }
  &__right {
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
  }
  &__person {
    &__name {
      text-transform: lowercase;
      font-size: 24px;
    }
    &__position {
      color: #d9d9d9;
      text-transform: lowercase;
    }
  }
  &__image {
    height: 100%;
    width: 120px;
    position: relative;
    img {
      position: absolute;
      right: 0;
      bottom: 0;
      max-height: 120%;
    }
    .multi-images {
      &:first-child {
        z-index: 2;
      }
      &:nth-child(2n) {
        right: 80px;
      }
      &:nth-child(3n) {
        right: 180px;
      }
      &:last-child {
        right: 140px;
      }
    }
  }
  @media (max-width: 640px) {
    height: auto;
    align-items: flex-start;
    height: auto;
    flex-direction: column;
    &__title {
      max-width: 75%;
    }
    &__time {
      height: 45px;
      background-color: #038047;
      font-size: 18px;
      border-radius: 12px;
    }
    &__container {
      width: auto;
      font-size: 18px;
      padding: 22px;
      &--lg {
        padding-bottom: 60px;
      }
    }
    &__person {
      position: absolute;
      bottom: 22px;
      left: 22px;
      &__name {
        font-size: 14px;
      }
      &__position {
        font-size: 8px;
      }
    }
    &__right {
      width: 100%;
      bottom: 0;
    }
    &__image {
      width: 100%;
      img {
        right: 0;
        width: 120px;
      }
      .multi-images {
        width: 80px;
        &:first-child {
          z-index: 2;
        }
        &:nth-child(2n) {
          right: 40px;
        }
        &:last-child {
          right: 75px;
        }
        &:nth-child(3n) {
          right: 115px;
        }
      }
    }
    &Img {
      width: 100%;
      .agendaItem__container {
        width: 100%;
        padding-bottom: 70px;
      }
    }
    &:after {
      width: 88px;
      height: 95px;
      right: 20px;
      bottom: 20px;
      top: inherit;
    }
  }
}
