.cRegistration {
  &__title {
    font-size: 28px;
    text-align: center;
    text-transform: lowercase;
    margin-bottom: 100px;
  }
  &-button {
    background-color: #14bb70;
    border: none;
    outline: none;
    height: 54px;
    border-radius: 14px;
    width: 100%;
    text-transform: lowercase;
    transition: all 0.3s ease;
    &:hover {
      background-color: #0e9e5f;
      cursor: pointer;
    }
  }
  &__form {
    width: 440px;
    margin: 0 auto;
    &-control {
      margin-bottom: 21px;
      label {
        font-size: 18px;
        color: #fff;
        text-transform: lowercase;
        margin-bottom: 13px;
        display: inline-block;
      }
      input {
        width: 100%;
        border: 1px solid #6a6a6a;
        height: 54px;
        border-radius: 14px;
        background-color: transparent;
        padding: 10px;
        // text-transform: lowercase;
        font-family: Arial, Helvetica, sans-serif;
        outline: none;
        transition: all 0.3s ease;
        &:focus {
          border: 1px solid #14bb70;
        }
      }
    }
  }
  @media (max-width: 640px) {
    &__title {
      font-size: 18px;
      margin-bottom: 40px;
    }
    &__form {
      width: 90%;
      margin: 0 auto;
    }
  }
}

input {
  font-family: 'Arial', sans-serif !important;
}

.ticketScan {
  position: relative;
  &-input {
    position: relative;
  }
  &-btn {
    position: absolute;
    right: 17px;
    height: 27px;
    border-radius: 14px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #cbcbcb;
    padding: 0 15px;
    top: 45px;
  }
  &-error {
    color: #ff0000;
    font-size: 12px;
  }
}

.scannerHeader {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 30px;
  &__logo {
    z-index: 9;
    position: relative;
    text-align: center;
    /* padding-top: 10vw; */
  }

  &__logo-image {
    width: 25vw;
  }
}

.message {
  color: #fff;
  width: 67vw;
  margin: 0 auto;
  padding: 3vw;
  border-radius: 2.5vw;
  text-align: center;
  background-color: #1d282b;
  /* margin-bottom: 10vw; */
  font-size: 3vw;

  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
