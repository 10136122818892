.navigation {
  width: 1280px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 50px;
  overflow: auto;

  @media (max-width: 640px) {
    .marque__item {
      color: #fff;
      padding: 10px 25px;
      border: 1px solid #6a6a6a;
      border-radius: 14px;
      margin-left: 10px;
      text-transform: lowercase;
      text-decoration: none;
      font-size: 12px;
      white-space: nowrap;
    }
    width: 100%;
  }
}
