.partner {
  &__container {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 33% 28% 33%;
    gap: 3%;
    &--v2 {
      grid-template-columns: 28% 28% 38%;
    }
    &--v3 {
      grid-template-columns: 28% 38% 28%;
    }
  }
  &__item {
    height: 135px;
    border-radius: 20px;
    border: 0.5px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      transition: all 0.3s ease;
      max-width: 60%;
      max-height: 60%;
      object-fit: cover;
      // filter: grayscale(1);
      opacity: 0.5;
    }
    &:hover {
      img {
        // filter: grayscale(0);
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: 640px) {
    &__container {
      grid-template-columns: 1fr;
      gap: 20px;
      margin-bottom: 20px;
      &--v2 {
        grid-template-columns: 1fr;
      }
      &--v3 {
        grid-template-columns: 1fr;
      }
    }
    &__item {
      height: 100px;
    }
  }
}
