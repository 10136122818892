.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  margin-top: 45px;
  &__logo {
    width: 179px;
    height: 53px;
    background: url(../../assets/imgs/logo.svg) no-repeat 50%;
    &-flex {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    &--container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding: 0 35px;
      border: 1px solid #6a6a6a;
      border-radius: 14px;
      height: 123px;
      display: flex;
      // align-items: flex-end;
      justify-content: center;
    }
    &-dot {
      width: 22px;
      height: 22px;
      background-color: #79adb9;
      border-radius: 50%;
      margin-left: 17px;
    }
  }
  &__cover {
    display: flex;
    align-items: center;
    &-text {
      color: #fff;
      font-size: 90px;
      &--small {
        font-size: 28px;
        color: #fff;
      }
    }
  }
  &__date {
    border: 1px solid #6a6a6a;
    border-radius: 14px;
    height: 123px;
    padding: 0 35px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &-text {
      font-size: 60px;
      color: #fff;
      span {
        font-size: 40px;
      }
      &--sm {
        font-size: 24px;
      }
      &--gray {
        color: #6a6a6a;
        margin-left: 20px;
        font-size: 12px;
        text-transform: lowercase;
      }
      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
      }
    }
  }
}

@media (max-width: 640px) {
  .header {
    margin-top: 18px;
    &__logo {
      width: 87px;
      height: 27px;
      background-size: cover;
      &-flex {
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      &--container {
        padding: 0 0;
        height: 61px;
        width: 144px;
      }
      &-dot {
        width: 10px;
        height: 10px;
        background-color: #79adb9;
        border-radius: 50%;
        margin-left: 17px;
      }
    }
    &__cover {
      display: none;
    }
    &__date {
      border: 1px solid #6a6a6a;
      border-radius: 14px;
      height: 105px;
      padding: 0 35px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 0px 25px;
      &-text {
        font-size: 40px;
        color: #fff;
        span {
          font-size: 40px;
        }
        &--sm {
          font-size: 20px;
        }
        &--gray {
          color: #6a6a6a;
          margin-left: 10px;
          font-size: 11px;
          text-transform: lowercase;
        }
        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #fff;
        }
      }
    }
  }
}
