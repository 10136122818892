@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap');

.creathon {
  &__title {
    font-size: 84px;
    text-align: center;
    text-transform: lowercase;
  }
  &__desc {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
  }
  &__by {
    text-align: center;
    margin-top: 90px;
    &-title {
      text-align: center;
    }
  }
  @media (max-width: 640px) {
    &__title {
      font-size: 28px;
    }
    &__desc {
      font-size: 16px;
      padding: 0 40px;
    }
  }
}

.brief {
  &__title {
    color: #ee2c3c;
    text-transform: lowercase;
    text-align: center;
    font-size: 44px;
    margin-top: 110px;
    margin-bottom: 20px;
  }
  &__desc {
    color: #fff;
    font-size: 24px;
    text-align: center;
    text-transform: lowercase;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 250px);
    grid-gap: 20px;
    justify-content: center;
    margin-top: 50px;
    &-item {
      height: 370px;
      padding: 40px 0;
      text-align: center;
      border: 1px solid #6a6a6a;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:first-child {
        background: url(../../assets/imgs/i1.png) no-repeat 50%;
      }
      &:nth-child(2) {
        background: url(../../assets/imgs/i2.png) no-repeat 50%;
      }
      &:nth-child(3) {
        background: url(../../assets/imgs/i3.png) no-repeat 50%;
      }
      &:last-child {
        background: url(../../assets/imgs/i4.png) no-repeat 50%;
      }
      &-title {
        font-size: 44px;
        color: #fff;
        text-align: center;
      }
      &-btn {
        text-decoration: underline;
        font-size: 24px;
      }
    }
  }
  &__faq {
    margin-top: 100px;
    &-title {
      text-align: center;
      font-size: 44px;
      text-transform: lowercase;
      margin-top: 50px;
    }
    &-desc {
      text-align: center;
      font-size: 24px;
      text-transform: lowercase;
      margin: 30px auto;
    }
    &-error {
      color: #ee2c3c;
      padding: 20px;
      border-radius: 14px;
      border: 1px solid #6a6a6a;
      color: #ee2c3c;
      display: table;
      margin: 0 auto;
      margin-top: 40px;
      text-transform: lowercase;
    }
    &-price {
      color: #d5b31c;
      padding: 20px;
      border-radius: 14px;
      border: 1px solid #6a6a6a;
      display: table;
      margin: 0 auto;
      margin-top: 40px;
      text-transform: lowercase;
    }
    &-btn {
      margin-top: 40px;
      text-align: center;
      background-color: #038047;
      font-size: 32px;
      border-radius: 14px;
      display: table;
      margin: 0 auto;
      margin-top: 30px;
      text-transform: lowercase;
      padding: 30px;
    }
  }
  @media (max-width: 640px) {
    &__desc {
      padding: 0 30px;
    }
    &__list {
      grid-template-columns: repeat(2, 40%);
      &-item {
        height: 250px;
        background-size: 70px !important;
      }
    }
    &__faq {
      &-desc {
        padding: 0 30px;
      }
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: none;
  * {
    font-family: 'Roboto', sans-serif !important;
  }
  &.active {
    display: flex;
  }
  &__container {
    background-color: #000;
    font-size: 16px;
    width: 50%;
    padding: 30px;
    text-transform: lowercase;
    max-height: 80vh;
    overflow: auto;
  }
  img {
    max-width: 100px;
  }
  &__title {
    text-transform: lowercase;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      width: 26px;
      height: 26px;
      background: url(../../assets/imgs/close-icon.png) no-repeat 50%;
      background-size: cover;
      cursor: pointer;
    }
  }
  .logos {
    display: flex;
    align-items: center;
    margin: 20px 0;
    justify-content: space-around;
  }
  &__desc {
    margin-top: 30px;
    font-size: 16px;
    text-transform: capitalize;
    b,
    div {
      font-size: 16px;
    }
    b {
      font-weight: bold;
    }
    div {
      margin: 10px;
    }
  }
  @media (max-width: 640px) {
    &__container {
      width: 90%;
    }
    .logos {
      display: grid;
      grid-template-columns: repeat(3, 27%);
      img {
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
