@import 'reset';

@font-face {
  font-family: 'Dirtyline36Daysoftype2022';
  src: url('../fonts/Dirtyline\ 36daysoftype\ 2022.otf') format('opentype');
}

* {
  color: #fff;
  font-family: Dirtyline36Daysoftype2022, sans-serif;
  box-sizing: border-box;
}

body {
  background-color: #000000;
  padding-bottom: 150px;
}

.title {
  font-size: 64px;
  text-transform: lowercase;
  margin-bottom: 95px;
  margin-top: 95px;
  text-align: center;
  color: #fff;
  @media (max-width: 640px) {
    margin-top: 60px;
    margin-bottom: 50px;
  }
  &-underlined {
    font-size: 44px;
    text-decoration: underline;
    display: table;
    margin: 0 auto;
    margin-top: 60px;
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 640px) {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 640px) {
  .info__container {
    display: none;
  }
}

video {
  transform: translate(-50%, -50%);
}
#video-bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.creation-btn {
  text-align: center;
  padding: 10px;
  text-align: center;
  background-color: #d5b31c;
  font-size: 25px;
  border-radius: 14px;
  display: table;
  margin: 0 auto;
  margin-top: 30px;
  text-transform: lowercase;
  padding: 15px 30px;
  text-decoration: none;
  animation: shadower 2s infinite alternate;
}

.miniPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__container {
    width: 520px;
    position: relative;
    height: 80vh;
    text-align: center;
    img {
      max-width: 480px;
      max-height: 480px;
      object-fit: contain;
    }
  }
  &__seemore {
    border-radius: 14px;
    cursor: pointer;
    position: absolute;
    left: 60px;
    top: 310px;
    background-color: #ff5765;
    padding: 20px;
    animation: shadower 2s infinite alternate;
    text-decoration: none;
  }
  &-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 26px;
    background: url(../../assets/imgs/close-icon.png) no-repeat 50%;
    background-size: cover;
    cursor: pointer;
  }
  @media (max-width: 640px) {
    &__container {
      width: 90%;
      height: 90vh;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__seemore {
      left: 8vw;
      top: 60vw;
      padding: 2.5vw;
    }
  }
}

@keyframes shadower {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  100% {
    box-shadow: 0 0 40px #ff5765;
  }
}
