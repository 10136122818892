.tickets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  width: 1200px;
  margin: 0 auto;
  &Card {
    border: 0.5px solid #6a6a6a;
    border-radius: 20px;
    padding: 70px 28px 45px 28px;
    * {
      text-align: center;
      color: #fff;
    }
    &__title {
      font-size: 44px;
      margin-bottom: 18px;
    }
    &__price {
      font-family: Arial, Helvetica, sans-serif;
      span {
        text-transform: lowercase;
      }
      font-size: 24px;
    }
    &__description {
      &--item {
        text-transform: lowercase;
        font-size: 14px;
        margin-bottom: 14px;
        text-align: left;
      }
      margin-bottom: 90px;
      margin-top: 80px;
    }
    &__button {
      text-transform: lowercase;
      height: 85px;
      font-size: 24px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid #ffffff;
      text-decoration: none;
      transition: all 0.3s ease;
      // &:hover {
      //   background-color: #d0c132;
      //   border: 0.5px solid #d0c132;
      // }
      &--red {
        border: 0.5px solid #ff5765;
        background-color: rgba(#ee2c3c, 0.7);
        &:hover {
          background-color: rgba(#ee2c3c, 0.9);
        }
      }
      &--purple {
        border: 0.5px solid #6310d0;
        background-color: rgba(#482683, 0.7);
        &:hover {
          background-color: rgba(#482683, 0.9);
        }
      }
      &--yellow {
        border: 0.5px solid #ffe987;
        background-color: rgba(#d5b31c, 0.7);
        &:hover {
          background-color: rgba(#d5b31c, 0.9);
        }
      }
    }
  }
  @media (max-width: 640px) {
    width: 90%;
    display: block;
    &Card {
      margin-bottom: 20px;
      &__button {
        height: 72px;
      }
    }
  }
}
