.title--agenda {
  padding-top: 40px;
  margin: 0 !important;
  padding-bottom: 60px;
}

.tab {
  display: flex;
  justify-content: center;
  &__item {
    margin-left: 45px;
    text-transform: lowercase;
    text-decoration: none;
    color: #fff;
    font-size: 24px;
    color: #6a6a6a;
    &.active {
      color: #fff;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
