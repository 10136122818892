.topic {
  &__card {
    color: #fff;
    height: 230px;
    border-radius: 20px;
    border: 1px solid #6a6a6a;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
      color: #fff;
      font-size: 44px;
      text-transform: lowercase;
      text-align: center;
    }
  }
  &__container {
    width: calc(1200px - 36px * 2);
    margin: 0 auto;
    display: grid;
    grid-template-columns: 25% 40% 35%;
    gap: 36px;

    &--v2 {
      margin-top: 36px;
      grid-template-columns: 25% 45% 30%;
    }
  }
  @media (max-width: 640px) {
    &__container {
      width: 90%;
      grid-template-columns: 100%;
      gap: 20px;
      &--v2 {
        grid-template-columns: 100%;
      }
    }
    &__card {
      height: 176px;
      &__title {
        font-size: 34px;
      }
    }
  }
}
