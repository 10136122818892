.info {
  &__container {
    margin-top: 150px;
    margin-bottom: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
  }
  text-align: center;
  &:first-child {
    .info__text {
      text-transform: capitalize;
    }
  }
  &__text {
    color: #fff;
    font-size: 64px;
    margin-bottom: 20px;
    text-transform: lowercase;
  }
  &__count {
    color: #fff;
    font-size: 44px;
  }
}
