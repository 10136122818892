.marque {
  &__item {
    color: #fff;
    padding: 14px 42px;
    border: 1px solid #6a6a6a;
    border-radius: 14px;
    margin-left: 25px;
    text-transform: lowercase;
    text-decoration: none;
  }
}
