.speakers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: calc(1280px - 60px);
  margin: 0 auto;
}

.speaker {
  position: relative;
  // width: 418px;
  &__location {
    width: 75px;
    height: 36px;
    border: 0.5px solid #6a6a6a;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    text-transform: lowercase;
  }
  &__top {
    position: relative;
  }
  &__content {
    height: 255px;
    background: url(../../assets/imgs/speaker-stroke.svg) no-repeat 50%;
    background-size: 100%;
    color: #fff;
    position: relative;
    // overflow: hidden;
  }
  &__company {
    font-size: 34px;
    position: absolute;
    top: 90px;
    left: 22px;
    z-index: 999;
    text-transform: lowercase;
  }
  &__image {
    position: absolute;
    right: 1px;
    bottom: -3px;
    z-index: 1;
    width: 100%;
    text-align: right;
    img:not(.speaker__art) {
      width: 240px;
      height: 300px;
      object-fit: cover;
    }
  }
  &__art {
    position: absolute;
    z-index: -1;
    top: 10%;
    left: 50%;
    transform: translateX(-40%);
  }
  &__position {
    width: 145px;
    height: 89px;
    border: 0.5px solid #6a6a6a;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    text-transform: lowercase;
  }
  &__name {
    font-size: 55px;
    color: #fff;
    margin-top: 24px;
    text-transform: lowercase;
    &--right {
      text-align: right;
    }
  }
}

@media (max-width: 640px) {
  .speakers {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }
  .speaker {
    width: 380px;
    margin: 0 auto;
    &__content {
      height: 250px;
    }
    &__location {
      width: 68px;
      height: 30px;
    }
    &__name {
      font-size: 44px;
      &--right {
        text-align: left;
        padding-left: 40px;
      }
    }
    &__position {
      font-size: 14px;
      text-align: left;
      width: 133px;
      height: 83px;
      padding-left: 5px;
    }
    &__company {
      font-size: 28px;
    }
    &__image {
      img:not(.speaker__art) {
        width: 220px;
        height: 270px;
      }
    }
  }
}
